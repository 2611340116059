import App from 'next/app';
import React from 'react';
import getConfig from 'next/config';
import { Trans } from '@lingui/react';
import * as Sentry from '@sentry/browser';
import { ThemeProvider } from 'styled-components';
import {theme} from "../src/globalStyles";
import LangProvider from '~/providers/LangProvider';
import polyfills from '../src/polyfills';
import redirectsClient from '../shared/redirects-client';
import AlertForIE from '../src/components/alertForIE';
import LinkExternal from '../src/components/link-external';
import { saveInvite } from '../src/components/referral';
import { saveUTMParams } from '../src/components/UTMParams';
import GlobalStyle from '~/globalStyles';

// Global styles for react-intl-tel-input
import 'react-intl-tel-input/dist/main.css';

const { publicRuntimeConfig } = getConfig();

export default class extends App {
    static async getInitialProps({ ctx, Component }) {
        // manually call getInitialProps on child component (just in case the page component is using it)
        const userAgent = ctx && ctx.req ? ctx.req.headers['user-agent'] : '';
        const pageProps = Component.getInitialProps
            ? await Component.getInitialProps(ctx)
            : {};

        const langProps = await LangProvider.getInitialProps(ctx);

        return { pageProps, langProps, userAgent };
    }

    constructor(props) {
        super(props);
        Sentry.init({ dsn: publicRuntimeConfig.SENTRY_DSN });
    }

    componentDidCatch(error, errorInfo) {
        Sentry.configureScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
        });
        Sentry.captureException(error);

        // This is needed to render errors correctly in development / production
        super.componentDidCatch(error, errorInfo);
    }

    componentDidMount() {
        polyfills();
        saveInvite();
        saveUTMParams();
        redirectsClient(this.props.langProps.clientLang);
    }

    render() {
        const { Component, pageProps, langProps, userAgent } = this.props;
        return (
            <>
                <ThemeProvider theme={theme}>
                    <AlertForIE ua={userAgent}>
                        <>
                            <Trans>
                                Bohužel Internet Explorer nepodporujeme,
                                doporučujeme stáhnout některý modernější prohlížeč:
                            </Trans>{' '}
                            <LinkExternal
                                href={'https://www.mozilla.org/cs/firefox/new/'}
                            >
                                Firefox
                            </LinkExternal>
                            <Trans>{' nebo '}</Trans>
                            <LinkExternal href={'https://www.google.com/chrome/'}>
                                Chrome
                            </LinkExternal>
                        </>
                    </AlertForIE>
                    <LangProvider {...langProps}>
                        <GlobalStyle />
                        <Component {...pageProps} />
                    </LangProvider>
                </ThemeProvider>
            </>
        );
    }
}
