import { createGlobalStyle } from 'styled-components';

// Global theme variables
export const theme = {
    primaryFont: '"Poppins", sans-serif',
    homepageTestimonialRoboto: '"Roboto", Segoe UI, sans-serif',
    lightPurple: '#EBE7F7',
    darkPurple: '#685BC7',
    darkPurple2: '#4941BD',
    darkPurple3: '#3B3472',
    greenColor: '#0CC8A8',
    lightGrey: '#616163',
    switchBoxShadow: '-5px 0 5px -2px #EBE7F7, 5px 0 5px -2px #EBE7F7',
    listDividerBorder: '1px solid #8D8FBD',
}

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "Poppins";
        src: url("/static/fonts/Poppins/Poppins-Bold.ttf") format("opentype");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Poppins";
        src: url("/static/fonts/Poppins/Poppins-Medium.ttf") format("opentype");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Poppins";
        src: url("/static/fonts/Poppins/Poppins-Regular.ttf") format("opentype");
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Poppins";
        src: url("/static/fonts/Poppins/Poppins-SemiBold.ttf") format("opentype");
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Poppins";
        src: url("/static/fonts/Poppins/Poppins-Light.ttf") format("opentype");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    * {
        box-sizing: border-box;
        margin: 0;
    }

    html {
        scroll-behavior: smooth;
    }

    input:invalid {
        box-shadow: none;
    }

    /* Fix for autocomplete blue background at input - Google chrome */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }


    input[type=tel] {
        border: none !important;
    }

    textarea {
        border: none !important;
    }

    .StyledLoader_spinner {
        position: fixed !important;
        top: 50% !important;
        left: 50% !important;
    }
`;

export default GlobalStyle;
