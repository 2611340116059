import React from 'react';
import { withI18n } from '@lingui/react';
import PropTypes from 'prop-types';

class LinkExternal extends React.Component {
    // @todo: detect utm and fill default
    // @todo: send info about outbound to GA
    render() {
        const url = this.props.href;
        const children = this.props.children;
        const className = this.props.className;
        return (
            <a
                href={url}
                target={'_blank'}
                className={className}
                rel="noopener noreferrer"
            >
                {children}
            </a>
        );
    }
}

LinkExternal.propTypes = {
    href: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

LinkExternal.defaultProps = {
    className: '',
};

export default withI18n()(LinkExternal);
