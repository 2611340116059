// old one-page hash navigation https://github.com/trisbee/web/issues/80
module.exports = clientLang => {
    if (
        window.location.hash.length > 1 &&
        window.location.pathname === '/' + clientLang + '/'
    ) {
        if ('#obchodnik' === window.location.hash) {
            window.location.replace('https://www.trisbee.com/cs/byznys');
        }
        if ('#zakaznik' === window.location.hash) {
            window.location.replace('https://www.trisbee.com/');
        }
        if (
            'malapokladna.cz' === window.location.host ||
            'www.malapokladna.cz' === window.location.host
        ) {
            window.location.replace('https://www.trisbee.com/cs/CZ/blog/mala-pokladna-uz-neni?referrer=https://www.malapokladna.cz');
        }
    }
};
